@import '../../../Styles/settings';

.h1 {
  @include HelveticaMedium;
  font-size: 24px;
  line-height: 28px;
  margin-top: 0;
  margin-bottom: 16px;
}

.li.li {
  border-bottom: 1px solid $color-tabs-nav-border-phone;
  padding: 0;
  font-size: 12px;
  overflow: hidden;

  &:first-of-type {
    border-top: 1px solid $color-tabs-nav-border-phone;
  }
}

.bazaarVoiceStars {
  margin-bottom: 16px;
}

.homeOwnerSubHeading {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  color: $near-black;
  margin-bottom: 16px;
}

.variantBtn {
  display: flex;
  flex-direction: row;
  gap: 4px;
  ::first-letter {
    text-transform: capitalize;
  }
}

.variantBtnImg {
  border-radius: 100%;
}

.chevron {
  transform: rotate(0deg);
  color: red;
}

.ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin: 0;
  column-gap: 4px;
}

.slideDownOuter {
  height: 0;
  overflow: hidden;
  transition: height 0.3s $main-timing-function;
}

.slideDownInner {
  padding-left: 5px;
}

.slideDownExpanded {
  height: auto;
}

.btn {
  @include HelveticaMedium;
  color: $midnight-blue;
  width: 100%;
  border: none;
  align-items: center;
  display: flex;
  background: transparent;
  font-size: 16px;
  padding: 16px 16px 16px 0px;
  text-decoration: none;
  letter-spacing: 0px;
  cursor: pointer;
  position: relative;
  z-index: map-get($zIndexes, 'tabsBtn');
  transition: color 0.3s $main-timing-function;
  border-radius: 4px 4px 0px 0px;

  svg {
    margin-right: 8px;
    margin-left: auto;
    height: 16px;
    width: 16px;
    transition: transform 0.2s $main-timing-function;
  }
  &.selected {
    color: $color-tabs-nav-selected;
    svg {
      transform: rotate(180deg);
    }
  }
}

.cvlTitle {
  @include HelveticaMedium;
  font-size: 16px;
  margin-bottom: 16px;
  line-height: 20px;
}

.cvlContent {
  &:first-letter {
    text-transform: capitalize;
  }
}

.cvlOptionContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.shortDescription {
  margin-top: 24px;
  line-height: 20px;
  font-size: 16px;
  color: $near-black;
}

.itemNbr {
  @include HelveticaMedium;
  display: block;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1.2px;
  margin-bottom: 8px;
  color: $darkest-gray;
}

.ul {
  padding-left: 0;
  list-style: none;
}

.liVisible {
  display: list-item;
}

.showMoreBtn {
  display: none;
}

.complianceContainer {
  position: absolute;
  top: 90px;
  right: 15px;
  z-index: map-get($zIndexes, 'productComplianceContainer');
}

.complianceImg {
  width: 30px;
  margin-bottom: 10px;
}

.attrContainerMaxWidth {
  width: 100%;
}

.cvlProperties {
  font-weight: 400;
  margin: 24px 0;
}

.cvl {
  border-top: 1px solid #dee1e6;
  &:last-of-type {
    border-bottom: 1px solid #dee1e6;
  }
  line-height: 20px;
  padding: 8px 0px;
}

.listItems {
  font-size: 12px;
  line-height: 16px;
  color: $darkest-gray;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #dee1e6;
  border-top: none;
  padding: 4px 12px;

  &:last-of-type {
    border-bottom: none;
  }

  &:first-of-type {
    padding-left: 0px;
  }
}

.productAttrListWrapper {
  white-space: pre-line;
  margin-bottom: 32px;
}

.sparePartShortDesc {
  margin: 32px 0;
}

@media #{$sm-up} {
  .complianceImg {
    width: 50px;
    margin-bottom: 20px;
  }
}

@media #{$md-up} {
  .homeOwnerSubHeading {
    color: $darkest-gray;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
  }

  .bazaarVoiceStars {
    margin-bottom: 24px;
  }

  .shortDescription {
    margin-top: 0px;
  }

  .slideDownInner {
    padding: 0;
  }

  .slideDownExpanded {
    margin-bottom: 32px;
  }

  .nav {
    padding: 0;
  }

  .li.li {
    font-size: 14px;
    padding: 0;
    &:first-of-type {
      border: none;
    }
    border: none;
  }

  .ul {
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  .attrContainerWrapper {
    display: flex;
  }
  .attrContainerMaxWidth {
    width: 100%;
  }

  .productAttrListWrapper {
    margin-bottom: 40px;
  }

  .showMoreBtn {
    @include textBtn;
    cursor: pointer;
    text-transform: inherit;
    letter-spacing: 1.5px;
    font-size: 14px;
  }

  .complianceContainer {
    position: relative;
    top: 5px;
    right: 0;
    margin-left: auto;
  }

  .h1 {
    font-size: 36px;
    margin-bottom: 16px;
    line-height: 40px;
  }
}
