@import '../../../Styles/settings';

.root {
  margin-bottom: 24px;
}

.cvlBtn {
  display: flex;
  align-items: center;
  justify-items: center;
  background-color: white;
  cursor: pointer;
  border: 1px solid $midnight-blue;
  border-radius: 4px;
  padding: 4px 8px;
  min-width: 48px;
  &:hover {
    background-color: $light-gray;
  }
}

.isSelected {
  background-color: $midnight-blue;

  .btnText {
    color: white;
  }

  &:hover {
    background-color: $midnight-blue;
  }
}

.isDisabled {
  background-color: $light-gray;
  border-style: dashed;
  border-color: $light-gray;

  .btnText {
    color: $darkest-gray;
  }

  &:hover {
    background-color: $light-cyan;
  }
}

.btnText {
  color: $midnight-blue;
  font-size: 12px;
  line-height: 16px;
  margin: auto;
}

@media #{$md-up} {
  .root {
    margin-bottom: 0;
  }
}