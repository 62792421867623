@import "../../../Styles/settings";

.qtyInput {
  background-color: white;
  border: 1px solid $bona-border-grey;
  border-radius: 4px;
  padding: 18px 12px 18px 16px;

  font-family: "Helvetica Neue LT W05_65 Medium";
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: $near-black;

  /* Firefox */
  -moz-appearance: textfield;
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.qtyInput:focus {
  outline: 1px solid $bona-border-grey;
}

.buttonsContainer {
  position: absolute;
  top: 12px;
  right: 5px;

  display: flex;
  flex-direction: column;
}

.button {
  border: none;
  background-color: transparent;

  padding: 0px 6px;
  height: 16px;
}

.header {
  font-family: "Helvetica Neue LT W05_65 Medium";
  line-height: 20px;
  font-weight: 500;

  margin-bottom: 16px;
}

.button:hover {
  cursor: pointer;
}

.size-s {
  width: 73px;
  height: 48px;
}

.size-m {
  width: 73px;
}

.size-xs {
  width: 53px;
}

.size-xxs {
  height: 40px;
  width: 48px;
  padding: 16px 0;

  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.inputBody {
  position: relative;
  width: fit-content;
}

.disabled {
  pointer-events: none;
  opacity: 0.8;
}
