@import '../../Styles/settings';

.label {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: $bona-dark-blue;

  margin-left: 12px;
  margin-bottom: 0;
}

.container {
  display: flex;
  align-items: center;
  width: max-content;
}
