@import '../../Styles/settings';

.alertContainer {
  padding: 16px;
  border-radius: 4px;
  background-color: $bona-light-blue;

  line-height: 20px;
  font-size: 16px;
  color: $bona-dark-blue;

  display: flex;
}
