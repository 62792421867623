@import "../../../Styles/settings";

.usedInSection {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.slideDownOuter {
  max-height: 0;
  transition: max-height 0.4s ease-out;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.slideDownInner {
  max-height: 193px;
  overflow-y: auto;
  transition: max-height 0.4s ease-in;
  transition-delay: 0.1s;
}

.text {
  font-family: "Helvetica Neue LT W05_65 Medium";
  line-height: 20px;
  font-size: 16px;

  margin-bottom: 8px;

  &.modalText {
    line-height: 16px;
    font-size: 12px;
    padding: 8px 0;
    width: 100%;
    margin-bottom: 0;
    border-bottom: 1px solid $light-cyan;

    &:last-of-type {
      border-bottom: none;
    }
  }

  &.sliderText {
    line-height: 16px;
    font-size: 12px;
    padding: 8px 0;
    width: 100%;
    margin-bottom: 0;
    border-bottom: 1px solid $light-cyan;
  }
}

.usedInHeader {
  color: $near-black;

  margin-bottom: 16px;
}

.usedInLink {
  color: $midnight-blue;
  text-decoration: none;
}

.alert {
  margin: 0 0 24px 0;
}

.modalContainer {
  overflow-y: hidden;
  position: absolute;
  top: 40px;
  width: 232px;
  box-shadow: 0px 10px 20px $silver;
  border-radius: 8px;
  z-index: 100;
  background-color: $white;

  .modal {
    margin: 8px;
    display: flex;
    flex-direction: column;
  }

  &.modalOn {
    overflow-y: auto;
    max-height: 150px;
    transition: max-height 0.5s ease-in;
  }

  &.modalOff {
    max-height: 0;
    transition: max-height 0.5s ease-in;
  }
}

.sellContainerSmall {
  display: flex;
  flex-wrap: wrap;

  & > div:first-of-type {
    width: 58px;
  }
}

.buttonsContainerSmall {
  display: flex;
  margin-top: 0;
  flex-wrap: wrap;
  width: calc(100% - 58px);

  & a {
    width: 100%;
    height: 40px;
    margin-bottom: 16px;
  }

  & a:nth-child(1) {
    order: 2;
  }

  & a:nth-child(2) {
    order: 1;
  }
}

.quantity {
  input {
    padding: 13px 12px 13px 16px;
    margin: 0;
  }

  div {
    top: 8px;
    right: 6px;
  }
}

.verticalContainer {
  display: flex;
  flex-wrap: wrap;

  & > div {
    width: 100%;
  }
}

.sellContainer {
  display: flex;
  flex-wrap: wrap;

  & > div:first-of-type {
    width: 83px;
  }
}

.buttonsVerticalContainer {
  display: flex;
  margin-top: 24px;
  justify-content: space-between;

  & a {
    width: calc((100% - 24px) / 2);
  }
}

.buttonsContainer {
  display: flex;
  margin-top: 0;
  flex-wrap: wrap;
  width: calc(100% - 83px);

  & a {
    width: 100%;
    height: 48px;
    margin-bottom: 16px;
  }

  & a:nth-child(1) {
    order: 2;
  }

  & a:nth-child(2) {
    order: 1;
  }
}

.quantitySmall {
  margin-bottom: 24px;
}

.nonStockLabel {
  margin-bottom: 24px;
}

@media #{$md-up} {
  .middleSegment {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    height: 100%;
  }

  .alert {
    margin: 8px 0 32px 0;
  }
}

@media #{$lg-up} {
  .middleSegment {
    padding-left: 9px;
  }
}
